import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const MAvatarDefault = () => {
  return (
    <Svg width="30" height="30" viewBox="0 0 30 30" fill="none">
      <Path
        d="M12.375 16.5C12.375 17.535 11.535 18.375 10.5 18.375C9.465 18.375 8.625 17.535 8.625 16.5C8.625 15.465 9.465 14.625 10.5 14.625C11.535 14.625 12.375 15.465 12.375 16.5ZM19.5 14.625C18.465 14.625 17.625 15.465 17.625 16.5C17.625 17.535 18.465 18.375 19.5 18.375C20.535 18.375 21.375 17.535 21.375 16.5C21.375 15.465 20.535 14.625 19.5 14.625ZM30 15C30 23.28 23.28 30 15 30C6.72 30 0 23.28 0 15C0 6.72 6.72 0 15 0C23.28 0 30 6.72 30 15ZM12.99 3.18C15.09 6.66 18.9 9 23.25 9C23.94 9 24.615 8.925 25.26 8.82C23.16 5.34 19.35 3 15 3C14.31 3 13.635 3.075 12.99 3.18ZM3.63 11.205C6.195 9.75 8.175 7.38 9.12 4.545C6.555 6 4.575 8.37 3.63 11.205ZM27 15C27 13.83 26.82 12.705 26.505 11.64C25.455 11.865 24.375 12 23.25 12C18.555 12 14.37 9.84 11.61 6.465C10.035 10.305 6.9 13.32 3 14.79C3.015 14.85 3 14.925 3 15C3 21.615 8.385 27 15 27C21.615 27 27 21.615 27 15Z"
        fill="black"
      />
    </Svg>
  );
};
